import React from 'react'
import cn from 'classnames';
import css from './styles.module.scss';
import { useTypedSelector } from '../../../store/common';

interface MyProps {
}
const SafeArea = (props: React.PropsWithChildren<MyProps>) => {
  const ActivePopUp = useTypedSelector(state => state.PurchaseState.ActivePopUp)
  return (
    < div className = { cn(css['wrapper'], ActivePopUp.isAuthInPurchase && css['wrapperAuth'])}>
      { props.children }
  </div >
)
}
export default SafeArea
